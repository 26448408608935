import { Box, FlexLayout, Text } from '~/ui';

export function Custom404Page() {
  return (
    <FlexLayout alignItems="center" flexGrow={1} justifyContent="center" space={4}>
      <Text variant="title-xl">404</Text>
      <Box as="span" sx={{ borderLeft: '1px solid', borderColor: 'white-alpha-25', height: '40px' }} />
      <Text variant="text-l">This page could not be found</Text>
    </FlexLayout>
  );
}
